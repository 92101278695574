import { connect } from 'react-redux'
import React, { Component } from 'react'
import VProgressLine from '../components/UploadsForm/ProgressLine'

class ProgressLine extends Component {

    render() {
        const id = this.props.doc.docId;
        const uploadId = this.props.uploadId;
        if (!this.props.uploads.uploads[id]) return;
        this.props.onUpdateStatus( uploadId );
        const percent = this.props.uploads.uploads[id].find( i => i.uploadId === this.props.uploadId ).parseFilePercent;
        return ( <VProgressLine percent={percent} /> );
    }
}

const mapStateToProps = ( { uploads, doc } ) => {
    return {
        uploads
       ,doc
    };
}

const mapDispatchToProps = dispatch => ({
    dispatch
   ,onUpdateStatus: ( uploadId ) => {
        if ( !uploadId ) return;
        const props = dispatch( ( dispatch, getState ) => getState() );
        const id = props.doc.docId;
        if ( !props.uploads.uploads[id] ) return;
        if ( !props.uploads.uploads[id].find( i => i.uploadId === uploadId && i.processingStatus === "NEW" && i.parseFilePercent !== 100  ) ) return;
    }

})

export default connect( mapStateToProps, mapDispatchToProps )( ProgressLine )
