import React from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import Multiline from "./Multiline";
import HiddenInput from "./HiddenInput";
import {
    GRAY
   ,HIDE
} from "../../redux/constants";
import Autocomplete/*, { createFilterOptions }*/ from '@material-ui/lab/Autocomplete';


//const _filter = createFilterOptions();

// Two states of Autocomplete: для правильной работы Autocomplete нужны оба состояния, одно будет с префиксом "xxcdoc_"
// 1) value/onChange() - selected value
// 2) inputValue/onInputChange() - displayed value in the textbox

// props.list - список

// через props можно передать функции, все необязательные:
// props.onInputChange( value ) - реакция на печать в поле ввода
// props.onSelectChange( value ) - выбор из списка
// props.onNameValueChange( name, value ) - реакция на печать и на выбор из списка, для обновления value и inputValue в state (без неё Autocomplete бывает глючит)
// props.onChange( e ) - чтобы можно было впечатать новое значение; без этого можно печатать, но любой текст не сохраняется, только выбор из списка
// props.onFocus - для заполнения списка в момент обращения, используется в компоненте StartrekIntegration

// readonly - только чтение, отрисовка полем Multiline
// selectonly - можно выбрать из списка, нельзя ввести новое значение,
// была идея отрисовать Select-ом, но тогда надо заполнять список, т.к. значения не из списка не отобразятся

// !visible - значит поля нет, его значение не определено,
// protect = hide - поле есть, но скрыто от внешнего пользователя

// в двуязычном шаблоне list = [{ id: Ru, name: En }]
// defaultValue и visibilityParentValue заданы по-русски, поле нужно отобразить на английском,
// Select так и работает по умолчанию, а для InputWithSelect используем флаг isLang

const InputWithSelect = ( props ) => {
 
    if( !props.visible && props.visible !== undefined ) return;
    const value = props.value === undefined || props.value === null ? '' : props.value;

    // protect для внешней формы
    if( props.isExternal && props.protect === HIDE )
    return (
        <HiddenInput
            name = { props.name }
            value = { value }
        />
    );

    const readonly = props.readonly || ( props.isExternal && props.protect === GRAY );
    const selectonly = props.selectonly;
    
    if( readonly ) return <Multiline {...props} />;
    
    // How to select option by id or by name in Autocomplete Material-UI
    // https://stackoverflow.com/questions/66998083/how-to-select-option-by-id-or-by-name-in-autocomplete-material-ui
    /*const filterOptions = ( options, state ) => {
        const result = _filter( options, state );

        if( result.length === 0 ) {
            return _filter( options, {
                ...state
               ,getOptionLabel: o => o.id.toString()
            });
        }

        return result;
    };*/

    const list = props.list;
    const isLang = props.isLang;
    
    const translateToLang = value => {
        // для двуязычных форм value = Ru, list = [{ id: Ru, name: En }], находим name по id
        if( isLang && typeof value === 'string' && Array.isArray( list ) ) {
            const index = list.findIndex( i => i.id === value );
            if( index > -1 ) value = list[ index ].name.toString();
        }
        return value;
    };
    
    const translateFromLang = value => {
        // для двуязычных форм value = En, list = [{ id: Ru, name: En }], находим id по name
        if( isLang && typeof value === 'string' && Array.isArray( list ) ) {
            const index = list.findIndex( i => i.name === value );
            if( index > -1 ) value = list[ index ].id.toString();
        }
        return value;
    };
    
    // замена props.onChange для двуязычных форм
    const onBlur = e => {
        
        e.target.value = translateFromLang( e.target.value );
        
        if( typeof props.onChange === "function" ) props.onChange( e );
    };
    
    
    return (

        <div className='padding'>
            <Autocomplete
            
                value={ value }
                onChange={ ( event, newValue ) => { // newValue может быть записью
                
                    // для двуязычных форм
                    // если newValue = запись { id: Ru, name: En }
                    if( isLang && newValue && newValue.id !== undefined ) newValue = newValue.id.toString();
                    // если newValue = значение En
                    else newValue = translateFromLang( newValue );
                
                    if( typeof props.onNameValueChange === "function" ) props.onNameValueChange( props.name, newValue );
                    if( typeof props.onSelectChange === "function" ) props.onSelectChange( newValue );
                }}
                
                inputValue = { props[ 'xxcdoc_' + props.name ] }
                onInputChange={(event, newValue) => {

                    newValue = translateFromLang( newValue );
                 
                    if( typeof props.onNameValueChange === "function" ) props.onNameValueChange( 'xxcdoc_' + props.name, newValue );
                    if( typeof props.onInputChange === "function" ) props.onInputChange( newValue );
                }}
        
                className={ props.className }
                options={ list ? list : [] }
                groupBy={ props.groupBy }
                
                // значения в выпадающем списке
                renderOption={ option => {
                    if( option === undefined || option === null ) {
                        return '';
                    }
                    if( typeof option === 'string' ) {
                        return option;
                    }
                    if( option.name !== undefined && option.name !== null ) {
                        return option.name;
                    }
                    return option;
                }}
                onFocus={ props.onFocus } // для заполнения списка в момент обращения, используется в компоненте StartrekIntegration

                //filterOptions={ filterOptions }
      
                // чтобы писал "Add <new value>" при добавлении нового значения, не из списка
                /*filterOptions={(options, params) => {
                 
                    const filtered = filter( options, params );

                    if (params.inputValue !== '') {
                        filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                        });
                    }
                    
                    return filtered;
                }}*/
                
                // значения в поле Input
                // кастомизация используется в RoleForm
                getOptionLabel={ option => {
                
                    option = translateToLang( option );
                
                    if( option === undefined || option === null ) {
                        return '';
                    }
                    if( typeof option === 'string' ) {
                        return option;
                    }
                    if( option.inputValue !== undefined && option.inputValue !== null ) {
                        return option.inputValue;
                    }
                    if( option.name !== undefined && option.name !== null ) {
                        return option.name;
                    }
                    return option;
                }}

                renderInput={ params => (
                    <TextValidator
                        { ...params }
                        multiline
                        label = { props.label }
                        name = { props.name }
                        value = { value } // значение поля уйдёт в form submit
                        InputLabelProps = {{ shrink: !!params.inputProps.value }} // поднять label над полем, иначе может наехать на значение
                        InputProps={{
                            ...params.InputProps
                           ,readOnly: selectonly
                           ,className: selectonly ? 'Mui-disabled' : 'MuiAutocomplete-inputRoot'
                        }}
                        // disabled = { selectonly } // disabled поля не попадают в submit form
                        onBlur={ onBlur } // было onBlur={ props.onChange }
                        validators = { props.validators }
                        errorMessages = { props.errorMessages }
                        helperText={ props.helperText }
                        variant = { props.variant }
                    />
                )}
            />
        </div>
    );
}

export default InputWithSelect
