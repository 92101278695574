import { connect } from 'react-redux';
import React, { Component } from 'react';
import { default as VAdminForm } from '../components/AdminForm/AdminForm';
import Item from '../components/AdminParts/Item';
import {
// properties
    APP_URL
// reducer names
   ,FORM_TEMPLATE
   ,MODAL
   ,GROUP_EDITOR
   ,TEMPLATE_EDITOR
   ,ITEM_EDITOR
   ,LIST_EDITOR
   ,EXT_EDITOR
   ,SESSION
} from '../redux/constants';
import {
    requestFile
   ,setDocStatePropValue
} from '../redux/actions/doc';
import {
    addField
   ,copyField
   ,insertFields
   ,deleteField
   ,docChange
   ,groupChange
   ,load
   ,loadStartrekFields
   ,loadTemplateItemsList
   ,loadTemplateDB
   ,saveTemplateDB
   ,uploadFile
   ,setTemplate
   ,setTemplateChangeFlag
   ,setTemplateItem
} from '../redux/actions/admin';
import {
    editListYandex
} from '../redux/actions/yandex';
import {
    deleteTemplateDB
} from '../redux/actions/templateEditor';
import {
    deleteGroupDB
} from '../redux/actions/groupEditor';
import {
    setKeyPropValue
   ,setProps
   ,setPropValue
} from '../redux/actions/utils';
import {
    getAdminItemList
   ,getOptionsListByItem
} from '../redux/actions/optionsList';
import {
    errorSnackbar
} from '../redux/actions/snackbar';
import {
    langList
   ,mainLang
   ,setLangEn
   ,yesNoList
   ,caption
} from '../redux/actions/lang';
import {
    clearCopyStore
   ,storeCopy
} from '../redux/actions/localStorage';

let cursorPosition = 0;


class AdminForm extends Component {

    componentDidUpdate( prevProps ) {
        // загрузить шаблон из базы, если его нет
        load( this.props, prevProps );
        
        // список полей шаблона
        // можно обновляить здесь, на каждое изменение полей,
        // но лучше по клику на "Добавить параметр"
        //if( this.props.doc.tmplItems === prevProps.doc.tmplItems ) // защита от цикла
        //    loadTemplateItemsList( this.props );
    }
    
    render() {
        const props = { ...this.props };

        const docId = props.doc.docId;
        const docTemplate = props.doc.docTemplates[ docId ];
        const docIdAdmin = props.doc.docAdminList.findIndex( i => i.id === docId );

        const lang = props.session.lang ?? "ru";

        const copy = props.session.copy; // Скопированные поля шаблона
        const copyLength = copy ? copy.length : 0;

        // docTemplate проверяем на каждое событие, потому что шаблон могут изменить в Админке
        
        // если есть компонента "Внешняя форма", можно получить внешнюю ссылку на форму
        const isExtShareable = Array.isArray( docTemplate ) && docTemplate.find( i => i.type === "Ext" ) !== undefined;

        // если есть компонента "Язык", можно сохранить варианты перевода в шаблоне
        const isLangEn = Array.isArray( docTemplate ) && docTemplate.find( i => i.type === "Lang" && i.isLangEn === true ) !== undefined;
        setLangEn( isLangEn );

        // добавить поле с переводом Названия поля
        
        // создать массив компонентов по шаблону формы
        const DynForm = !props.doc.docParts || !Array.isArray( docTemplate ) || docIdAdmin === -1 ? null :
            docTemplate.map( (item, key) => Item( {
                item
               ,isExtShareable
               ,isLangEn
               ,lang
               ,copyLength
               ,docPart: props.doc.docParts[ item.type ]
               ,globalLists: props.doc
               ,docParts: props.doc.docParts // компоненты
                // item и docParts нужны в onItemChange()
                // если меняем Тип компонента, нужно сбросить его атрибуты,
                // кроме тех, что есть в компоненте нового типа
               ,onChange: (e) => props.onItemChange( e, key, item, props.doc.docParts ) // для компонентов с event-ами
               ,onNameValueChange: ( name, value ) => props.dispatch( setKeyPropValue( FORM_TEMPLATE, key, name, value ) ) // для компонентов без event-ов
               ,onAddField:  () => props.onAddField( key )
               ,onCopyField: () => props.onCopyField( item )
               ,onInsert:    () => { props.onInsert( key, copy ); props.onClearSelection(); }
               ,onEditItem:  () => props.onEditItem( key, item, props ) // редактор дополнительных атрибутов поля (edit); из props получим список полей
               ,onEditList:  () => props.onEditList( key, item, props ) // редактор списка для списочного поля
               ,onEditExt:   () => props.onEditExt( key, item ) // редактор дополнительных атрибутов поля (ext)
               ,onDeleteField: () => props.onDeleteField( key )
               ,dispatch: props.dispatch // для вызова loadIssueTypes() из компоненты IssueType
               
               // OEBS-38281 поля Startrek - START
               // TODO не нравится, что эти атрибуты передаются в каждый item
               ,startrekFieldsList: props.doc.startrekFieldsList
               ,tmplItems: props.doc.tmplItems
               ,onSelectedValue: ( value, name ) => props.onSelectedValue( key, name, value, item.textWithVariables )
               ,onKeyUp: (e) => props.onKeyUp( e, key )
               ,onChangeSaveStartSelection: (e) => { props.onItemChange( e, key, item, props.doc.docParts );
                                                     props.onKeyUp( e, key ); }
               ,onLoadStartrekFieldsList: () => props.onLoadStartrekFieldsList( props, docTemplate )
               ,onLoadTemplateItemsList: () => props.onLoadTemplateItemsList( props )
               // OEBS-38281 поля Startrek - END

               // TODO можно всё передать через props, как в DocForm.js?
               
            } ) );
            
        props.DynForm = DynForm;
        props.docTemplate = docTemplate;
        props.isExtShareable = isExtShareable;
        props.docIdAdmin = docIdAdmin;
        props.lang = lang;

        return (
            <VAdminForm
                { ...props }
            />
        )
    }
}


const mapStateToProps = ( { doc, role, user, spin, session } ) => {
    return {
        doc
       ,role
       ,user
       ,spin
       ,session
    };
};

// lang - добавляем перевод
function copyTranslatedAttributes( item, newItem, attrName ) {
    langList.forEach( i => {
        if( i === mainLang ) return;
        const name = attrName + '_' + i;
        const value = item[ name ];
        if( value !== undefined ) newItem[ name ] = value;
    });
}

// если меняем Тип компонента, нужно сбросить его атрибуты,
// кроме тех, что есть в компоненте нового типа;
// fieldsList сбрасываем - Список полей разный у сложных компонентов,
// item - старый компонент, меняем на новый - docPart
function copyItemAttributes( item, docPart ) {
    // названия атрибутов нового поля
    const attributes = []
    .concat( !docPart.admin ? [] : docPart.admin.reduce( (res, i, idx) => { if( !i.readonly ) res.push( i.name ); return res; }, [] ) )
    .concat( !docPart.edit  ? [] : docPart.edit.reduce(  (res, i, idx) => { if( !i.readonly ) res.push( i.name ); return res; }, [] ) );

    // оставить только допустимые атрибуты для нового типа,
    // пустые сбросить,
    // если атрибуты readonly и имеют defaultValue, тоже сбросить старые значения
    const filtered = Object.keys( item )
    .reduce( (obj, key) => {
        if( attributes.includes( key ) && item[ key ] !== '' && key !== 'fieldsList' ) {
            obj[ key ] = item[ key ];
            if( [ "label", "tooltip"/*, "defaultValue", "visibilityParentValue"*/ ].includes( key ) )
                copyTranslatedAttributes( item, obj, key );
        }
        return obj;
    }, {});
  
    // копируем список
    if( docPart.isList && Array.isArray( item.list ) ) {
        filtered.list = item.list;
        copyTranslatedAttributes( item, filtered, "list" );
    }
    
    return filtered;
}


const mapDispatchToProps = dispatch => ({
    dispatch // передаю dispatch в props, для Item и load
    //==============================
    // admin form
    //==============================
    // prevent firing button on Enter
   ,onFormKeyPress: ( e ) => {
        // allow Enter in textarea
        var txtArea = /textarea/i.test((e.target || e.srcElement).tagName);
        if( txtArea || (e.keyCode || e.which || e.charCode || 0) !== 13 ) {
            return true;
        } else {
            e.preventDefault();
        }
        return false;
    }
    // форму убрать нельзя, Material UI компоненты привязываются к форме
   ,onFormSubmit: ( e ) => {
        e.preventDefault();
        
        const docState = dispatch( ( dispatch, getState ) => getState().doc );
        const docId = docState.docId;
        const template = docState.docTemplates[ docId ];
        saveTemplateDB( dispatch, docId, template )
    }
   ,onFormError: () => {
        errorSnackbar( caption.onFormError );
    }
   ,onDocChange: ( e ) => docChange( dispatch, e )
   ,onGroupChange: ( e, oldGroupId ) => groupChange( dispatch, e, oldGroupId )
   ,onLoadTemplate: ( e ) => {
        const file = e.target.files[0];
        e.target.value = ''; // reset, to enable same file re-opening
        dispatch( ( dispatch, getState ) => uploadFile( dispatch, getState, file ) )
    }
   ,onSaveTemplate: ( docId ) => {
    
        if( !Number.isInteger( docId ) ) return;
        const formData = new FormData();
        formData.append('docId', docId);

        requestFile( APP_URL + "/api/doc/template", "template", "rtf", dispatch, formData ); // TODO rtf / pdf / docx
    }
   ,onSaveSampleTemplate: ( e ) => {
        e.preventDefault();
        //requestFile( APP_URL + "/api/doc/sample", "Образец шаблона и руководство", "rtf", dispatch );
        window.open( "https://cdoc.daas.yandex-team.ru/admins/create-template/guide/index.html" );
    }
    // кнопка "+" внизу
   ,onAppendField: ( e ) => {
        dispatch( addField( {
            type: "Input"
        } ) );
        e.preventDefault();
    }
   ,onAdd: ( groupId ) => {
        dispatch(
            setProps( TEMPLATE_EDITOR, {
                open: true
               ,goal: 'add'
               ,templateName: ''
               ,templateCode: ''
               ,groupId
            } )
        )
    }
   ,onEdit: ( docId, docNames ) => {
        const docName = docNames.find( i => i.id === docId );
        dispatch(
            setProps( TEMPLATE_EDITOR, {
                open: true
               ,goal: 'edit'
               ,docId
               ,templateName: docName.name
               ,templateCode: docName.code
               ,groupId: docName.group_id
            } )
        )
    }
   ,onCopy: ( docId, docNames ) => {
        const docName = docNames.find( i => i.id === docId );
        dispatch(
            setProps( TEMPLATE_EDITOR, {
                open: true
               ,goal: 'copy'
               ,docId
               ,templateName: docName.name + " 2"
               ,templateCode: docName.code ? docName.code + " 2" : ''
               ,groupId: docName.group_id
            } )
        )
    }
   ,onDelete: ( docId, groupId ) => {
        dispatch( setProps( MODAL, {
            msg: caption.deleteForm + "?"
           ,onOk: () => deleteTemplateDB( dispatch, docId, groupId )
        } ) )
    }
   ,onGroupAdd: () => {
        dispatch(
            setProps( GROUP_EDITOR, {
                open: true
               ,goal: 'add'
               ,groupName: ''
            } )
        )
    }
   ,onGroupEdit: ( groupId, groupNames ) => {
        const groupName = groupNames.find( i => i.id === groupId ).name;
        dispatch(
            setProps( GROUP_EDITOR, {
                open: true
               ,goal: 'edit'
               ,groupId
               ,groupName
            } )
        )
    }
   ,onGroupDelete: ( groupId, docNames ) => {
        const idx = docNames.findIndex( i => i.group_id === groupId );
        if( idx > -1 ) {
            dispatch( setProps( MODAL, { msg: caption.onGroupDelete } ) );
            return;
        }
        
        dispatch( setProps( MODAL, {
            msg: caption.deleteGroup + "?"
           ,onOk: () => deleteGroupDB( dispatch, groupId )
        } ) )
    }
   /* перенесли в onFormSubmit, чтобы работали validators
   ,onSave: ( docId, template ) => {
        saveTemplateDB( dispatch, docId, template );
    }*/
   ,onLoad: ( docId, docAdminList, init = false ) => {
        loadTemplateDB( dispatch, docId, docAdminList, init );
    }
    
    //==============================
    // field editor
    //==============================
   ,onItemChange: ( e, key, item, docParts ) => {
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked :
                      target.value;

        // если меняем Тип компонента, нужно сбросить его атрибуты,
        // кроме тех, что есть в компоненте нового типа
        if( name === "type" ) {
            const docPart = docParts[ value ];
            if( !docPart ) return;
            const newItem = copyItemAttributes( item, docPart );
            dispatch( setTemplateItem( key, newItem ) );
        }
        
        // если изменили optionName,
        // список компонента обновится на форме при перерисовке,
        // а выбранное значение нужно сбросить здесь, по событию,
        // т.к. оно больше не соответствует списку,
        // будет заполнено из defaultValue при перерисовке,
        // для defaultValue тоже сбрасываем, но это не обязательно
        if( name === "optionName" || name === "defaultValue" ) {
            dispatch( setDocStatePropValue( item.name, undefined ) );
        }
        
        dispatch( setKeyPropValue( FORM_TEMPLATE, key, name, value ) );
    }
   ,onOrderChange: ( items ) => { // изменился проядок полей после перетаскивания
        dispatch( setTemplate( items ) );
        dispatch( setTemplateChangeFlag( true ) );
    }
   ,onAddField: ( key ) => { // кнопка "+" сверху на поле
        dispatch( addField( {
            type: "Input"
        }, key ) );
    }
   ,onCopyField: ( item ) => { // кнопка "Добавить копию поля" сверху на поле
        dispatch( copyField( item ) );
        storeCopy( item ); // сохранить между сессиями
    }
   ,onInsert: ( key, fields ) => { // вставить скопированне поля перед указанным полем
        dispatch( insertFields( key, fields ) );
    }
   ,onClearSelection: () => {
        dispatch( setPropValue( SESSION, 'copy', [] ) );
        clearCopyStore();
    }
   ,onEditItem: ( key, item, props ) => { // открыть редактор дополнительных атрибутов поля (edit) - Карандаш

        // список полей в шаблоне, для управления видимостью
        const itemList = loadTemplateItemsList( props );
        // Select value = id, то есть тег, а
        // InputWithSelect value = name, видимое значение, поэтому нужно перевести id -> name
        // и обратно после редактирования - в ItemEditor.js onOk
        const selectedItem = itemList.find( i => i.id === item.visibilityParentName );
        const visibilityParentName = selectedItem ? selectedItem.name : '';
        // список из поля, управлеющего видимостью
        let visibilityParentList = selectedItem ? props.docTemplate[ selectedItem.index ].list : undefined;
        if( selectedItem && selectedItem.type === "Checkbox" ) visibilityParentList = yesNoList;
        
        // lang - добавляем перевод
        // Карандаш - модальное окно без переключателя языка,
        // достаточно подменить атрибуты в item до открытия и после нажатия OK
        let visibilityParentValue = item[ 'visibilityParentValue' ];
        /* OLD VERSION
        const lang = props.lang;
        if( lang !== 'ru' ) visibilityParentValue = item[ 'visibilityParentValue_' + lang ]; */
        
        dispatch(
            setProps( ITEM_EDITOR, {
                ...item
               ,visibilityParentName
               ,visibilityParentValue
               ,visibilityParentList
               ,itemList
               ,open: true
               ,idx: key // "idx", потому что нельзя называть "key", React скушает из props
            } )
        )
    }
   ,onEditList: ( key, item, props ) => { // открыть редактор списка
   
        if( item.type === "Yandex" && item.optionsList !== "yaBGs" ) {
            editListYandex( key, item, props );
            return;
        }

        const translatedList = item.list;
        
        // optionsList - полный список значений, доступных для отбора в список list,
        // может быть задан в свойствах компонента,
        // массив [...] или имя массива в props.doc
        const optionsList = getOptionsListByItem( props.doc, item );

        
        const list = getAdminItemList( translatedList, optionsList, "name" ); // список из шаблона или из optionsList

        let list_en = Array.isArray( item.list_en ) ? [ ...item.list_en ] : [];
        if( Array.isArray( optionsList ) ) list_en = undefined;
        
        dispatch(
            setProps( LIST_EDITOR, {
                open: true
               ,idx: key // "idx", потому что нельзя называть "key", React скушает из props
               ,list
               ,list_en
               ,optionsList: Array.isArray( optionsList ) ? optionsList.map( i => i.name ) : undefined
            } )
        )
    }
   ,onEditExt: ( key, item ) => { // открыть редактор дополнительных атрибутов поля (ext) - Видимость поля на внешней форме

        dispatch(
            setProps( EXT_EDITOR, {
                ...item
               ,open: true
               ,idx: key // "idx", потому что нельзя называть "key", React скушает из props
            } )
        )
    }
   ,onDeleteField: ( key ) => {
        dispatch( setProps( MODAL, {
            msg: caption.deleteField + "?"
           ,onOk: () => dispatch( deleteField( key ) )
        } ) );
    }
    
    // OEBS-38281 поля Startrek - START
   ,onSelectedValue: ( key, name, value, text ) => {

        if( !value ) return;
        let textToInsert = value.id;
        cursorPosition = cursorPosition === undefined ? 0 : cursorPosition;
        text = text === undefined ? '' : text;
        let textBeforeCursorPosition = text.substring( 0, cursorPosition );
        let textAfterCursorPosition = text.substring( cursorPosition, text.length );
        let newValue = textBeforeCursorPosition + "<?" + textToInsert +"?>" + textAfterCursorPosition;

        dispatch( setKeyPropValue( FORM_TEMPLATE, key, name, newValue ) );
    }
   ,onKeyUp: ( e, key ) => {
        cursorPosition = e.target.selectionStart;
    }
   ,onLoadStartrekFieldsList: ( props, docTemplate ) => {
        // заполнение списка startrekFieldsList по клику на "Поле Трекера"
        const startrekFieldsList = props.doc.startrekFieldsList;
        const queueList = docTemplate.filter( i => i.type === "SaveAndTicket" ).map( i => i.startrekQueue );
        if( !Array.isArray( startrekFieldsList ) || !startrekFieldsList.length )
            loadStartrekFields( dispatch, queueList.toString() );
    }
   ,onLoadTemplateItemsList: ( props ) => {
        // заполнение списка tmplItems по клику на "Добавить параметр"
        loadTemplateItemsList( props );
    }
    // OEBS-38281 поля Startrek - END
    
});

export default connect( mapStateToProps, mapDispatchToProps )( AdminForm );
