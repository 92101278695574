import React from 'react'
import DateInput from '../DocParts/DateInput';
import Input from '../DocParts/Input';
import Button from '../AdminParts/Button';
import styles from "./Form.module.css";
import {
    caption
} from '../../redux/actions/lang';


const UploadsFilter = ({ props, uploads }) => {
    return (
        <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginTop: "16px" }}>
            <div>
                <DateInput
                    label={ caption.filterLoadFrom }
                    name="dateFrom"
                    value={ uploads.dateFrom }
                    onChange={ date => { props.onNameValueChange( "dateFrom", date ); props.onUpdateUploads(); } }
                    invalidDateMessage=''
                    maxDateMessage=''
                    minDateMessage=''
                />
            </div>
            <div>
                <DateInput
                    label={ caption.filterLoadTo }
                    name="dateTo"
                    value={ uploads.dateTo }
                    onChange={ date => { props.onNameValueChange( "dateTo", date ); props.onUpdateUploads(); } }
                    invalidDateMessage=''
                    maxDateMessage=''
                    minDateMessage=''
                />
            </div>
            <div>
                <DateInput
                    label={ caption.filterRunFrom }
                    name="dateProcFrom"
                    value={ uploads.dateProcFrom }
                    onChange={ date => { props.onNameValueChange( "dateProcFrom",  date ); props.onUpdateUploads(); } }
                    invalidDateMessage=''
                    maxDateMessage=''
                    minDateMessage=''
                />
            </div>
            <div>
                <DateInput
                    label={ caption.filterRunTo }
                    name="dateProcTo"
                    value={ uploads.dateProcTo }
                    onChange={ date => { props.onNameValueChange( "dateProcTo", date ); props.onUpdateUploads(); } }
                    invalidDateMessage=''
                    maxDateMessage=''
                    minDateMessage=''
                />
            </div>
            <div style={{ marginTop: "-1px" }}>
                <Input
                    label={ caption.numberOfLines }
                    name="limit"
                    value={ uploads.limit }
                    onChange={ e => { props.onChange( e ); props.onUpdateUploads(); } }
                />
            </div>
            <div style={{ marginTop: "7px" }}>
                <Button
                    name="update"
                    onClick={ props.onUpdateUploads }
                    label={ caption.update }
                />
            </div>
            <div>
                <Input
                    className={ styles.xxAutorInput }
                    label={ caption.author }
                    name="login"
                    value={ uploads.login }
                    validators={[]}
                    onChange={ e => { props.onChange( e ); props.onUpdateUploads(); } }
                />
            </div>
        </div>
    )
}

export default UploadsFilter;
