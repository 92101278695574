import React, { Component } from 'react';
import VProgressCircle from '../components/UploadsForm/ProgressCircle';
import { connect } from 'react-redux'

class ProgressCircle extends Component {

    render() {
        const id = this.props.doc.docId;
        const uploadId = this.props.uploadId;

        const percent = !uploadId ? 45 : this.props.uploads.uploads[id].find( i => i.uploadId === uploadId).percent;
        const label = this.props.statusLabel;

        this.props.onUpdateStatus( uploadId );

        return (
            <VProgressCircle percent = {percent} label = {label} />
        )
    }
}

const mapStateToProps = ( { uploads, doc } ) => {
    return {
        uploads
       ,doc
    }
    ;
}

const mapDispatchToProps = dispatch => ({
    dispatch
   ,onUpdateStatus: ( uploadId ) => {
        if ( !uploadId ) return;
        const props = dispatch( ( dispatch, getState ) => getState() );
        const id = props.doc.docId;
        if ( !props.uploads.uploads[id] ) return;
        if ( !props.uploads.uploads[id].find( i => i.uploadId === uploadId && i.processingStatus === "IN_PROGRESS" ) ) return;
    }
})

export default connect( mapStateToProps, mapDispatchToProps )( ProgressCircle )
