import React from 'react'
import styles from "./Form.module.css"
import Button from './Button';
import MButton from '@material-ui/core/Button';
import Card from "@material-ui/core/Card";
import HiddenInput from "./HiddenInput";
import { TextValidator } from 'react-material-ui-form-validator';
import {
    HIDE
} from "../../redux/constants";

const captions = {
    "ru": {
        saveDoc: "Сохранить документ"
       ,saveDocAndCreateTicket: "Сохранить документ и создать тикет в очереди "
       ,createTicketInQueue: "Создать тикет в очереди "
       ,saveFile: "Сохранить файл"
       ,savePdfExt: "Сохранить PDF"
       ,savePdf: "Сохранить PDF"
       ,saveRtfExt: "Сохранить документ"
       ,saveRtf: "Сохранить документ"
       ,send: "Отправить"
       ,sendPdf: "Отправить PDF"
       ,sendRtf: "Отправить документ"
       ,ticketPdf: "Тикет с PDF"
       ,ticketRtf: "Тикет с документом"
       ,sendAndSave: "Отправить и сохранить в файл"
       ,sendAndSavePdf: "Отправить и сохранить PDF"
       ,sendAndSaveRtf: "Отправить и сохранить документ"
       ,ticketAndSavePdf: "Создать тикет и сохранить файл PDF"
       ,ticketAndSaveRtf: "Создать тикет и сохранить документ"
       ,ticketPdfRtfNopass: "Создать тикет (PDF с паролем и Word)"
       ,sendToYandex: "Подписанный документ можно отправить обратно вашему контактному лицу"
       ,file: "Файл"
       ,chooseFile: "Выбрать файл"
       ,helperText: <>Чтобы отправить документ:<br/>
            1)	Заполните форму, нажмите «Сохранить в файл», подпишите полученный документ,<br/>
            2)	Нажмите «Выбрать файл» и выберите файл с подписанным документом,<br/>
            3)	Нажмите «Отправить».</>
       ,docPasswordProtected: "Документ защищён от редактирования паролем"
       ,docNopass: "Документ без пароля"
    }
   ,"en": {
        saveDoc: "Save the document"
       ,saveDocAndCreateTicket: "Save the document and create a ticket in the queue "
       ,createTicketInQueue: "Create a ticket in the queue "
       ,saveFile: "Save file"
       ,savePdfExt: "Save PDF"
       ,savePdf: "Save PDF"
       ,saveRtfExt: "Save document"
       ,saveRtf: "Save document"
       ,send: "Send"
       ,sendPdf: "Send PDF"
       ,sendRtf: "Send document"
       ,ticketPdf: "Ticket with PDF"
       ,ticketRtf: "Ticket with document"
       ,sendAndSave: "Send and save to file"
       ,sendAndSavePdf: "Send and save PDF"
       ,sendAndSaveRtf: "Send and save document"
       ,ticketAndSavePdf: "Create a ticket and save PDF file"
       ,ticketAndSaveRtf: "Create a ticket and save document"
       ,ticketPdfRtfNopass: "Create a ticket (PDF with password and Word)"
       ,sendToYandex: "You can send signed document back to your contact person"
       ,file: "File"
       ,chooseFile: "Select file"
       ,helperText: <>To send document:<br/>
            1)	Fill in the form, click “Save to file”, sign the downloaded document,<br/>
            2)	Click “Select file” and choose the file with the signed document,<br/>
            3)	Click “Send”.</>
       ,docPasswordProtected: "Document is protected from editing with a password"
       ,docNopass: "Document without a password"
    }
};


const SaveAndTicket = ( props ) => {
 
    if( !props.visible && props.visible !== undefined ) return;
 
    // protect для внешней формы
    const isExternal = props.isExternal;
    const hidden = isExternal && props.protect === HIDE;
    if( hidden ) return;

    // в условиях учтены значения по умолчанию - true для кнопок с паролем, false для дополнительных, без пароля
    const isPassword = props.isProtected && ( props.isDocPdf !== false || props.isDocRtf !== false || props.isTicketPdf !== false || props.isTicketRtf !== false );
    const isNopass = props.isProtected && ( props.isDocPdfNopass === true || props.isDocRtfNopass === true || props.isTicketPdfNopass === true || props.isTicketRtfNopass === true );

    const isDoc = props.isDocPdf !== false || props.isDocRtf !== false || props.isDocPdfNopass === true || props.isDocRtfNopass === true
        || props.isDocAndTicketPdf === true || props.isDocAndTicketRtf === true;
    const isTicket = props.isTicketPdf !== false || props.isTicketRtf !== false || props.isTicketPdfNopass === true || props.isTicketRtfNopass === true
        || props.isDocAndTicketPdf === true || props.isDocAndTicketRtf === true || props.isTicketPdfRtfNopass === true || props.isUploadFile === true;
    const isVisible = isDoc || isTicket;

    if( !isVisible ) return;
    
    const lang = props.lang ? props.lang : "ru";
    const caption = captions[ lang ] ? captions[ lang ] : captions[ "ru" ];

    let label = null;
    if( isDoc && isTicket ) label = caption.saveDocAndCreateTicket + props.startrekQueue;
    else if( isDoc ) label = caption.saveDoc;
    else if( isTicket ) label = caption.createTicketInQueue + props.startrekQueue;

    const file = props.value;
    let value = file === undefined || file === null ? '' : file.name;
    value = value === undefined || value === null ? '' : value;

    
    const startrekQueue =
        <HiddenInput
            name = "startrekQueue"
            value = { props.startrekQueue }
        />;

    const startrekIssueType =
        <HiddenInput
            name = "startrekIssueType"
            value = { props.issueType }
        />;
        
    const docPdf = props.isDocPdf === false ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onSaveDoc( 'pdf' ) }
            label = {
                isExternal ?
                    props.isDocRtf === false ? caption.saveFile : caption.savePdfExt
                : caption.savePdf
            }
        />;
        
    const docRtf = props.isDocRtf === false ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onSaveDoc( 'rtf' ) }
            label = {
                isExternal ?
                    props.isDocPdf === false ? caption.saveFile : caption.saveRtfExt
                : caption.saveRtf
            }
        />;
            
    const ticketPdf = props.isTicketPdf === false ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onCreateTicket( 'pdf' ) }
            label = {
                isExternal ?
                    props.isTicketRtf === false ? caption.send : caption.sendPdf
                : caption.ticketPdf
            }
        />;
            
    const ticketRtf = props.isTicketRtf === false ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onCreateTicket( 'rtf' ) }
            label = {
                isExternal ?
                    props.isTicketPdf === false ? caption.send : caption.sendRtf
                : caption.ticketRtf
            }
        />;
            
    const docPdfNopass = props.isDocPdfNopass !== true ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onSaveDoc( 'pdf', true ) }
            label = {
                isExternal ?
                    props.isDocRtfNopass !== true ? caption.saveFile : caption.savePdfExt
                : caption.savePdf
            }
        />;
            
    const docRtfNopass = props.isDocRtfNopass !== true ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onSaveDoc( 'rtf', true ) }
            label = {
                isExternal ?
                    props.isDocPdfNopass !== true ? caption.saveFile : caption.saveRtfExt
                : caption.saveRtf
            }
        />;
            
    const ticketPdfNopass = props.isTicketPdfNopass !== true ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onCreateTicket( 'pdf', true ) }
            label = {
                isExternal ?
                    props.isTicketRtfNopass !== true ? caption.send : caption.sendPdf
                : caption.ticketPdf
            }
        />;
            
    const ticketRtfNopass = props.isTicketRtfNopass !== true ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onCreateTicket( 'rtf', true ) }
            label = {
                isExternal ?
                    props.isTicketPdfNopass !== true ? caption.send : caption.sendRtf
                : caption.ticketRtf
            }
        />;

    
    // далее идут кнопки для внешнего пользователя;
    // есть возможность, что пользователь скачает документ, подпишет, и договор станет активным без нашего ведома,
    // поэтому нужны кнопки "Скачать и создать тикет"
    
    const docAndTicketPdf = props.isDocAndTicketPdf !== true ? '' :
        <Button
            className = "button100"
            onClick = { () => props.onDocAndTicket( 'pdf' ) }
            label = {
                isExternal ?
                    props.isDocAndTicketRtf !== true ? caption.sendAndSave : caption.sendAndSavePdf
                : caption.ticketAndSavePdf
            }
        />;
            
    const docAndTicketRtf = props.isDocAndTicketRtf !== true ? '' :
        <Button
            className = "button100"
            onClick={ () => props.onDocAndTicket( 'rtf' ) }
            label = {
                isExternal ?
                    props.isDocAndTicketPdf !== true ? caption.sendAndSave : caption.sendAndSaveRtf
                : caption.ticketAndSaveRtf
            }
        />;
    
    const ticketPdfRtfNopass = props.isTicketPdfRtfNopass !== true ? '' :
        <Button
            className = "button100"
            onClick={ () => props.onTicketPdfRtfNopass() }
            label = {
                isExternal ? caption.send : caption.ticketPdfRtfNopass
            }
        />;
        
    // создать тикет с подписанным документом
    const uploadFile = props.isUploadFile !== true ? '' :
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
        <div className='smallText padding'>{ caption.sendToYandex }:</div>
       
        <div className='padding' style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <TextValidator
                    className={ props.className }
                    label={ caption.file }
                    name={ props.name }
                    value={ value }
                    InputLabelProps={{ shrink: !!value }} // поднять label над полем, иначе может наехать на значение
                    onChange={ f=>f }
                    validators={ props.validators }
                    errorMessages={ props.errorMessages }
                    autoFocus={ props.autoFocus }
                    InputProps={{
                        readOnly: props.readonly
                       ,style: props.bold ? {fontWeight: 550} : {}
                       ,className: props.readonly ? 'Mui-disabled' : undefined
                    }}
                    // disabled={ props.readonly } // disabled поля не попадают в submit form
                    helperText={ props.helperText }
                    error={ props.error }
                />
                <MButton variant="contained" color="primary" component="label" style={{ margin: '10px 0px 0px 10px', minWidth: '140px', maxHeight: '36px' }}>
                    { caption.chooseFile }
                    <input
                      type="file"
                      // accept=".rtf"
                      onChange={ props.onChange }
                      style={{ display: 'none' }}
                      name={ props.name }
                    />
                </MButton>
            </div>
            
            <div className={ styles.xxHelperText } >
                { caption.helperText }
            </div>
        </div>
        
        <div className='table__row'>
        <Button
            className = "button100"
            onClick = { () => props.onCreateTicketWithFile( value ) }
            label = { caption.send }
        />
        </div>

        </div>;
        
        
    const content =
        <>
        { startrekQueue }
        { startrekIssueType }
        
        { !isExternal && isPassword ? <div className='smallText padding'>{ caption.docPasswordProtected }</div> : '' }
        
        <div className='table__row'>
            { docPdf }
            { docRtf }
        </div>
        
        <div className='table__row'>
            { ticketPdf }
            { ticketRtf }
        </div>
        
        { !isExternal && isNopass ? <div className='smallText padding'>{ caption.docNopass }</div> : '' }
        
        <div className='table__row'>
            { docPdfNopass }
            { docRtfNopass }
        </div>
        
        <div className='table__row'>
            { ticketPdfNopass }
            { ticketRtfNopass }
        </div>
        
        <div className='table__row'>
            { docAndTicketPdf }
        </div>
        
        <div className='table__row'>
            { docAndTicketRtf }
        </div>
        
        <div className='table__row'>
            { ticketPdfRtfNopass }
        </div>
        
        <div className='table__row'>
            { uploadFile }
        </div>
        
        </>;
    
    if( isExternal ) return content;
    
    return (

        <Card className={ styles.xxComponentCard }>
        <div className={ styles.xxComponentCardLabel }>{ label }</div>
        <div className={ styles.xxComponentCardContent } style={{ paddingBottom:"20px" }} >
        
        { content }
        
        </div>
        </Card>
        
    );
}

export default SaveAndTicket
