import React from 'react';
import Input from "./Input";
import InputWithSelect from "./InputWithSelect";

const StartrekIntegration = ( props ) => {
    return (
        <>
            <InputWithSelect
                label = { props.listLabel } // Добавить параметр
                name="addFieldData"
                list = { props.list } // список, отсортированный для группировки
                //list = { props.list.sort( ( a, b ) => a.componentLabel.localeCompare( b.componentLabel ) ) } // сортировка для группировки
                itemName={ props.name }
                onFocus={ props.onFocus } // для заполнения списка в момент обращения
                onNameValueChange = { f=>f }
                onInputChange = { f=>f }
                onSelectChange={ props.onSelectChange }
                groupBy = { option => option.componentLabel } // группировка для полей составных компонентов по заголовку компонента
            />
            <Input className={ props.className }
                multiline
                variant="outlined"
                value={ props.value === undefined || props.value === null ? '' : props.value }
                onChange={ props.onChange }
                onInputChange={ props.onInputChange }
                onKeyUp={ props.onKeyUp }
                visible={ props.visible }
                label={ props.label }
                name={ props.name }
                helperText={ props.helperText }
            />
        </>
    );
};

export default StartrekIntegration;
