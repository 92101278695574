import axios from 'axios';
import {
    // actions
    SET_KEY_PROP_VALUE
   ,SET_DOC_KEY_PROP_VALUE
    // properties
   ,APP_URL
   ,UPLOADS
} from '../constants';
import {
    post_common
} from '../actions/utils';
import { errorSnackbar, successSnackbar } from "./snackbar";
import { COLOR_BLUE, COLOR_GREEN, COLOR_LIGHT_GREY, COLOR_RED, COLOR_WHITE, COLOR_YELLOW } from "../../constants";
import { requestFile } from "./doc";
import {
    getUploads
} from '../../containers/UploadsForm';
import {
    caption
   ,gLang
} from './lang';

export let statusList;
export const getStatusList = () => ( statusList = [
    { id: "ALL",         name: caption.statusAll,       color: COLOR_WHITE      }
   ,{ id: "NEW",         name: caption.statusNew,       color: COLOR_BLUE       }
   ,{ id: "UPLOAD",      name: caption.statusNew,       color: COLOR_BLUE       }
   ,{ id: "IN_PROGRESS", name: caption.statusRun,       color: COLOR_BLUE       }
   ,{ id: "CANCEL",      name: caption.statusCancel,    color: COLOR_LIGHT_GREY }
   ,{ id: "CANCELED",    name: caption.statusCancelled, color: COLOR_LIGHT_GREY }
   ,{ id: "ERROR",       name: caption.statusError,     color: COLOR_RED        }
   ,{ id: "OK",          name: caption.statusOk,        color: COLOR_GREEN      }
   ,{ id: "WARNING",     name: caption.statusWarning,   color: COLOR_YELLOW     }
]);

export const setUploadPropValue = ( name, value, key ) => (
    {
        type: UPLOADS + SET_KEY_PROP_VALUE
       ,payload: {
            name
           ,value
           ,key
        }
    }
);

export const setUploadDocPropValue = ( docId, name, value, key ) => (
    {
        type: UPLOADS + SET_DOC_KEY_PROP_VALUE
       ,payload: {
            docId
           ,name
           ,value
           ,key
        }
    }
);

// Скачать шаблон
export function loadMassDocGenerationTemplate( dispatch, docId ) {

    if( !Number.isInteger( docId ) ) return;
    
    const formData = new FormData();
    formData.append( 'docId', docId );
    // formData.append( 'xxcdoc_lang', gLang ); // уже передаётся в requestFile()
    // если передать два раза - Spring проигнорирует параметр!

    requestFile( APP_URL + "/api/uploads/massDocGenerationTemplate", caption.massTemplate, "xls", dispatch, formData );
}

// Запустить обработку
export function startMassDocGeneration( dispatch, uploadId, docId ) {
    const formData = new FormData();
    formData.append( 'uploadId', uploadId );
    formData.append( 'xxcdoc_lang', gLang );
    
    const config = {headers: {'content-type': 'multipart/form-data'}};
    post_common(
        APP_URL + '/api/uploads/startDocGeneration'
       ,formData
       ,config
       ,dispatch
       ,caption.massLoadError + ': '
       ,data => {
            if( data !== 'ERROR' ) {
                dispatch( setUploadDocPropValue( docId,  uploadId, data, "processingStatus" ) );
            } else {
                errorSnackbar( caption.massLoadError );
            }
       }
    );
}


// загрузить файл пользователя для масс-загрузки
let cancelRequest;
export function loadMassDocGenerationFile ( dispatch, getState, file ) {

    const docId = getState().doc.docId;
    if( !Number.isInteger( docId ) ) return;

    const formData = new FormData();
    formData.append( 'file', file );
    formData.append( 'fileName', file.name );
    formData.append( 'docId', docId );
    formData.append( 'xxcdoc_lang', gLang );

    const config = {headers: {'content-type': 'multipart/form-data'}};

    if( cancelRequest ) {
        cancelRequest.cancel();
    }
    cancelRequest = axios.CancelToken.source();

    post_common(
        APP_URL + '/api/uploads/loadDocGenerationFile'
       ,formData
       ,config
       ,dispatch
       ,caption.massResultError + ': '
       ,data => {
            if( data !== 'ERROR' ) {
                getUploads( dispatch );
                successSnackbar( caption.fileSaved );
            } else {
                errorSnackbar( caption.massResultError );
            }
       }
    );
}

// Изменить статус
export function changeUploadStatus ( dispatch, docId, uploadId, status ) {
    const formData = new FormData();
    formData.append( 'uploadId', uploadId );
    formData.append( 'status', status );
    const config = { headers: { 'content-type': 'multipart/form-data' } };

    post_common(
        APP_URL + '/api/uploads/changeStatus'
       ,formData
       ,config
       ,dispatch
       ,caption.uploadStatusError + ': '
       ,data => {
            if( data !== 'ERROR' ) {
                dispatch( setUploadDocPropValue( docId,  uploadId, data, "processingStatus" ) );
            } else {
                errorSnackbar( caption.uploadStatusError );
            }
        }
    );
}
