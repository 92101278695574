import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {buildStyles, CircularProgressbarWithChildren} from "react-circular-progressbar";
import {COLOR_BLUE} from "../../constants";

const ProgressCircle = ( props ) => {

    return (<Tooltip title={props.label} placement="bottom">
                <div style={{width: 40, height: 40}}>
                    <CircularProgressbarWithChildren value={props.percent} styles={buildStyles({
                        rotation: 0,
                        strokeLinecap: 'butt',
                        textSize: '16px',
                        circleRatio: '5',
                        pathTransitionDuration: 0.5,
                        pathColor: COLOR_BLUE, // `rgba(62, 152, 199, ${45 / 100})`,
                        textColor: COLOR_BLUE, // `rgba(62, 152, 199, ${45 / 100})`,
                        trailColor: '#d6d6d6',
                        backgroundColor: COLOR_BLUE,
                    })}>
                        <div style={{fontSize: 10, marginTop: -8}}>
                            <strong>{props.percent}%</strong>
                        </div>
                    </CircularProgressbarWithChildren>
                </div>
            </Tooltip>
    );
}

export default ProgressCircle;
